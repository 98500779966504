.footer {
    background-color: #0056b8;
    width: 100%;
    position: absolute;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, #0056b8, #116dd763, #0056b8) 1;
    padding-top: 15px;
}

/*img*/
.img-footer-container {
    width: 100%;
    text-align: center;
}

.img-footer {
    width: 160px;
}

/*list*/
.footer-ul {
    margin-top: 45px;
    text-transform: uppercase;
    color: #fff;
    list-style-type: none;
    text-align: center;
    width: 100%;
    text-decoration: none;
}

.li-element {
    /*mismos estilos que para los li de la lista del nav*/
    font-weight: bold;
    font-size: 16px;
    display: inline;
    /* margin-right: 60px; */
    text-decoration: none;
}

.li-element:hover {
    color: #ff6e00;
}

/*
.li-element:hover{
    color: #ff6e00;
    -webkit-text-stroke: 0.7px #fff;
    font-size: 13px;
}
*/

/*copy*/
.copy-text {
    /* margin-top: 35px; */
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 200;
    font-family: sans-serif;
    margin-bottom: 0;
    padding: 15px 0;
}

/*copy link*/
.copy-text>a {
    /* margin-top: 35px; */
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 200;
    font-family: sans-serif;
    text-decoration: none;
}

.copy-text>a:hover {
    color: white;
    text-decoration: underline;
}

/*queries*/
@media (max-width: 481px) {
    .footer {
        width: 100%;
        position: absolute;
    }
    .li-element {
        display: block;
    }
}

@media (max-width: 768px) {
    .footer-ul-list {
        display: flex;
        flex-direction: column;
    }
    .footer {
    }
}

.footer-nav{
    padding-top: 10px;
    margin-top: 10px;
}

@media (min-width: 570px) and (max-width: 768px) {
    .footer-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
    }

    .img-footer-container{
        border-right: 1px solid rgba(228, 228, 228, 0.047);
    }

    .footer .nav-ul{
        row-gap: 5px;
    }
}

.footer-nav{
    display: flex;
    align-items: center;
    column-gap: 1px;
}

.footer .nav-ul .link{
    display: flex;
    align-items: center;
    width: 215px;
    max-width: 215px;
    height: 27px;
    max-height: 27px;
    justify-content: center;
}

.footer .nav-ul .li-element{
    transition: all .4s;
    width: 175px;
    max-width: 175px;
}

.footer .nav-ul .li-element:hover{
    font-size: 18px !important;
    color: #f26d32;
    margin-top: -3px;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    transition: all .4s;
    width: 175px;
    max-width: 175px;
}
