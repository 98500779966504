.white {
    width: 100%;
    height: 70px;
}

.products-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    padding-bottom: 80px

}

/*div de la izquierda, contiene los ingredientes*/
.flavor-ingredients {
    width: 17%;
    flex-direction: column;
}

.ingredient {
    width: 50%;
    margin: 0 auto;
    display: flex;
    height: auto;
    margin-bottom: 10px;
}

/*div del medio, contiene el producto y el texto*/
.product {
    width: 66%;
    text-align: center;
    display: flex;
}

.product-img-div {
    margin-top: 8px;
    width: 50%;
}

.product-img {
    animation: MoveUpDown 2s linear infinite;
}

@keyframes MoveUpDown {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(13px);
    }
}

/*div que engloba al title y al texto*/
.product-text-div {
    margin-left: 5px;
    width: 50%;
    text-align: left;
}

.product-title {
    font-size: 40px;
    text-transform: uppercase;
    color: #ff6e00;
    font-weight: bolder;
    width: 100%;
    letter-spacing: 1px;
    font-family: Nauticus Sans, sans-serif;
}

.product-title2 {
    color: #0056b8;
}

.product-text {
    width: 90%;
    color: #7a7a7a;
    font-family: Muli, sans-serif;
    font-size: 20px;
    font-weight: lighter;
}

/*div de la derecha, contiene la tabla nutricional*/
.nutritional-facts {
    width: 17%;
    margin-right: 5px;
}

.nutritional-img {
    text-align: center;
    display: flex;
    width: 90%;
    margin: 0 auto;

}

/*queries*/
@media (max-width: 480px) {
    .white {
        width: 100%;
        height: 10px;
    }

    /*section de los productos*/
    .products-section {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .products-section2 {
        margin-bottom: 0px;
        padding-bottom: 80px;
    }

    /*div de la izquierda, contiene los ingredientes*/
    .flavor-ingredients {
        display: none;
        width: 17%;
        flex-direction: column;
    }

    .ingredient {
        width: 48%;
        margin: 0 auto;
        display: flex;
        height: auto;
        margin-bottom: 10px;
    }

    /*div del medio, contiene la imagen del producto y el texto*/
    .product {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    .product-img-div {
        margin-top: 8px;
        width: 60%;
        margin: 0 auto;
    }

    .product-img {
        margin-bottom: 20px;
        width: 240px;
    }
    /*div que engloba al title y al texto*/
    .product-text-div {
        width: 95%;
        margin-top: 15px;
        margin: 0 auto;
        text-align: center;
    }

    .product-title {
        font-size: 28px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 auto;

    }

    .product-text {
        margin: 0 auto;
        margin-top: 10px;
        color: #767f8a;
        width: 95%;
        font-family: Muli, sans-serif;
        font-size: 15px;
    }

    /*div de la derecha, contiene la tabla nutricional*/
    .nutritional-facts {
        width: 47%;
        margin: 0 auto;
    }

    .nutritional-img {
        text-align: center;
        display: flex;
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;

    }
}
@media (min-width: 481px) and (max-width: 580px) {
    .white {
        width: 100%;
        height: 10px;
    }

    /*section de los productos*/
    .products-section {
        margin-top: 60px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .products-section2 {
    padding-bottom: 60px;
    }

    /*div de la izquierda, contiene los ingredientes*/
    .flavor-ingredients {
        display: none;
    }

    /*div del medio, contiene la imagen del producto y el texto*/
    .product {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    .product-img-div {
        margin-top: 8px;
        width: 50%;
        margin: 0 auto;
    }

    .product-img {
        margin-bottom: 20px;
        width: 270px;
    }
    /*div que engloba al title y al texto*/
    .product-text-div {
        width: 95%;
        margin-top: 15px;
        margin: 0 auto;
        text-align: center;
    }

    .product-title {
        margin-top: 5px;
        font-size: 28px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 auto;

        width: 90%;
    }

    .product-text {
        margin: 0 auto;
        margin-top: 10px;
        width: 95%;
        color: #767f8a;
        font-family: Muli, sans-serif;
        font-size: 18px;
        text-align: center;
        margin-bottom: 30px;
    }

    /*div de la derecha, contiene la tabla nutricional*/
    .nutritional-facts {
        margin: 0 auto;
        width: 220px;
        text-align: center;
        margin-bottom: 30px;
    }
    .nutritional-img {
        text-align: center;
        display: flex;

    }
}

@media (min-width: 581px) and (max-width: 768px) {
    .white {
        width: 100%;
        height: 10px;
    }

    /*section de los productos*/
    .products-section {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .products-section2 {
        margin-top: 50px;
        padding-bottom: 80px;
    }

    /*div de la izquierda, contiene los ingredientes*/
    .flavor-ingredients {
        display: none;
    }

    /*div del medio, contiene la imagen del producto y el texto*/
    .product {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    .product-img-div {
        margin-top: 8px;
        width: 50%;
        margin: 0 auto;
    }

    .product-img {
        margin-bottom: 20px;
        width: 270px;
    }
    /*div que engloba al title y al texto*/
    .product-text-div {
        width: 95%;
        margin-top: 15px;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 20px;
    }

    .product-title {
        font-size: 28px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 auto;
    }

    .product-text {
        margin: 10px 0 15px 6px;
        margin-top: 10px;
        width: 95%;
        color: #767f8a;
        font-family: Muli, sans-serif;
        font-size: 20px;
        text-align: center;
    }

    /*div de la derecha, contiene la tabla nutricional*/
    .nutritional-facts {
        margin: 0 auto;
        width: 220px;
        text-align: center;
    }
    .nutritional-img {
        text-align: center;
        display: flex;

    }
}

@media (min-width: 769px) and (max-width: 990px) {
    .white {
        width: 100%;
        height: 10px;
    }

    .products-section {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .products-section2 {
        margin-top: 0px;
        padding-bottom: 80px;
    }

    /*div de la izquierda, contiene los ingredientes*/
    .flavor-ingredients {
        width: 20%;
        flex-direction: column;
    }

    .ingredient {
        width: 50%;
        margin: 0 auto;
        display: flex;
        height: auto;
        margin-bottom: 10px;

    }

    /*div del medio, contiene el producto y el texto*/
    .product {
        width: 60%;
        text-align: center;
        display: flex;
    }

    .product-img-div {
        margin-top: 8px;
        width: 50%;
    }

    .product-img {
        width: 85%;
    }

    /*div que engloba al title y al texto*/
    .product-text-div {
        margin-left: 5px;
        width: 50%;
        text-align: left;
    }

    .product-title {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 1000;
    }

    .product-text {
        width: 95%;
        color: #767f8a;
        font-family: Muli, sans-serif;
        font-size: 15px;
        font-weight: lighter;
    }

    /*div de la derecha, contiene la tabla nutricional*/
    .nutritional-facts {
        width: 17%;
    }

    .nutritional-img {
        text-align: center;
        display: flex;
        width: 100%;

    }
}

@media (min-width: 990px) and (max-width: 1200px) {
    .white {
        width: 100%;
        height: 70px;
    }

    .products-section {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .products-section2 {
        margin-top: 20px;
        padding-bottom: 80px;
    }

    /*div de la izquierda, contiene los ingredientes*/
    .flavor-ingredients {
        width: 17%;
        flex-direction: column;
    }

    .ingredient {
        width: 50%;
        margin: 0 auto;
        display: flex;
        height: auto;
        margin-bottom: 10px;
    }

    /*div del medio, contiene el producto y el texto*/
    .product {
        width: 66%;
        text-align: center;
        display: flex;
    }

    .product-img-div {
        margin-top: 8px;
        width: 50%;
    }

    .product-img {
        width: 90%;
    }

    /*div que engloba al title y al texto*/
    .product-text-div {
        margin-left: 5px;
        width: 50%;
        text-align: left;

    }

    .product-title {
        font-size: 40px;
        text-transform: uppercase;
        font-weight: bolder;
        width: 80%;
    }

    .product-text {
        width: 90%;
        color: #7a7a7a;
        font-family: Muli, sans-serif;
        font-size: 16px;
        font-weight: lighter;
    }

    /*div de la derecha, contiene la tabla nutricional*/
    .nutritional-facts {
        width: 17%;
        margin-right: 5px;
    }

    .nutritional-img {
        text-align: center;
        display: flex;
        width: 90%;
        margin: 0 auto;
    }
}

@media(min-width: 2000px){

    .products-section {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    /*div de la izquierda, contiene los ingredientes*/
    .flavor-ingredients {
        width: 17%;
        flex-direction: column;
    }

    .ingredient {
        width: 50%;
        margin: 0 auto;
        display: flex;
        height: auto;
        margin-bottom: 10px;
    }

    /*div del medio, contiene el producto y el texto*/
    .product {
        width: 66%;
        text-align: center;
        display: flex;
    }

    .product-img-div {
        margin-top: 8px;
        width: 50%;
    }

    .product-img {
        animation: MoveUpDown 2s linear infinite;
    }

    /*div que engloba al title y al texto*/
    .product-text-div {
        margin-left: 5px;
        width: 50%;
        text-align: left;
    }

    .product-title {
        font-size: 50px;
        text-transform: uppercase;
        color: #ff6e00;
        font-weight: bolder;
        width: 100%;
        letter-spacing: 1px;
        font-family: Nauticus Sans, sans-serif;
    }

    .product-title2 {
        color: #0056b8;
    }

    .product-text {
        width: 90%;
        color: #7a7a7a;
        font-family: Muli, sans-serif;
        font-size: 30px;
        font-weight: lighter;
    }

    /*div de la derecha, contiene la tabla nutricional*/
    .nutritional-facts {
        width: 17%;
        margin-right: 5px;
    }

    .nutritional-img {
        text-align: center;
        display: flex;
        width: 90%;
        margin: 0 auto;

    }
}

.division-shape-bottom-products{
    bottom: -1px;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}

.division-shape-bottom-products svg{
    width: calc(157% + 1.3px);
    height: 43px;
}

.elementor-shape-fill-products{
    fill: #ff6e00;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    rotate: 180deg;
}
