.contact {
    background:#0056b8;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative;
}

.contact-title {
    margin-top: 40px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 45px;
    font-family: Nauticus Sans;
}

.form {
    color: #fff;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "formsFields" "formsChecks" "phone-container";
}

input,
textarea,
select {
    background-color: transparent !important;
    border: 1px solid #fff;
    color: white;
    padding-left: 10px;
}

.input {
    width: 250px;
    height: 50px;
    margin: 10px;
}

/*input que ocupa la totalidad*/
.input-all {
    width: 525px;
}

.textarea {
    width: 524px;
    margin: 5px;
    height: 120px;
    padding-top: 10px;
}


::placeholder { /*estilos para las palabras dentro de las etiquetas del form*/
    padding: 8px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.666);
    font-family: sans-serif;
}

select {
    color: rgba(255, 255, 255, 0.666);
    border: 1px solid #fff;
}

.select-space {
    padding-left: 8px;
}

.select-input {
    color: #000;
    font-size: 15px;
}

option {
    color: black;
}

.check-boxes {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-left: 10px;
    font-size: 15px;
    margin-bottom: 30px;
    /* font-weight: 400!important; */
}

input[type="checkbox"] {
    transform: scale(0.95);
}

.check-boxes > label {
    font-weight: 300 !important;
    font-family: sans-serif;
}

label {
    display: inline-flex!important;
    gap: 0.5rem;
    align-items: flex-start;
}

.send-button {
    background-color: #f46d33;
    border: none;
    color: #fff;
    border: 3px solid #fff;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-weight: 700;
}

.send-button:hover {
    text-decoration: none;
    background-color: #fff;
    border: 3px solid #f46d33;
    color: #f46d33;
    transition: all 0.5s;
}

.contact_success{
    background-color: #7adf3c;
    border: none;
    color: #fff;
    border: 3px solid #fff;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-weight: 700;
}

.error-message{
    background-color: rgb(234, 76, 76);
    padding: 5px 10px;
    font-family: Helvetica, sans-serif;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 10px;
}

/*queries*/
@media (max-width: 481px) {
    .contact-title {
        font-size: 35px;
    }
    label {
        font-size: 10px;
    }
}

@media (max-width: 767px) {
    .contact {
        width: 100%;
    }

    label {
        font-size: 14px;
    }

    .contact-title {
        text-align: center;
        color: #fff;
    }
    .form {
        margin: 0 auto;
        text-align: center;
    }

    input,
    textarea,
    select {
        background-color: transparent !important;
        border: 1px solid #fff;
    }

    .input {
        width: 90%;
    }

    .input-all {
        width: 90%;
    }

    .textarea {
        width: 90%;
        margin: 5px;
        height: 90px;
    }

    ::placeholder {
        padding: 8px;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.666);
    }

    .check-boxes {
        color: rgb(255, 255, 255);
        font-size: 15px;
        width: 530px;
        text-align: left;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    input[type="checkbox"] {
        transform: scale(0.95);
    }

    /*check inputs*/
    .check-boxes {
        color: #fff;
        font-size: 14px;
        width: 90%;
        text-align: left;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .send-button {
        background-color: #f46d33;
        border: 2px solid #fff;
        color: #fff;
        text-transform: uppercase;
        font-size: 11px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 500;
    }

    .send-button {
        margin: 0 auto;
        background-color: #f46d33;
        border: 3px solid #fff;
        color: #fff;
        text-transform: uppercase;
        font-size: 15px;
        padding-left: 34px;
        padding-right: 34px;
        padding-top: 16px;
        padding-bottom: 16px;
        font-weight: 500;
    }
}

/* @media (min-width: 1180px)  {

    .phone-container {
        position: relative;
        text-align: right;
        display: inline;
        right: -450px;
        top: -500px;
    }

} */

@media (min-width: 768px) and (max-width: 1024px) {

    .contact-title {
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 40px;
        font-family: Nauticus Sans;
    }

    .form {
        color: #fff;
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
    }

    input,
    textarea,
    select {
        background-color: transparent !important;
        border: 1px solid #fff;
    }

    .input {
        width: 200px;
        height: 50px;
        margin: 10px;
    }

    /*input que ocupa la totalidad*/
    .input-all {
        width: 422px;
    }

    .textarea {
        width: 423px;
        margin: 5px;
        height: 120px;
    }

    ::placeholder {
        padding: 8px;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.666);
    }

    select {
        appearance: none;
        border: 1px solid #fff;
    }

    .check-boxes {
        color: rgb(255, 255, 255);
        font-size: 15px;
        width: 420px;
        text-align: left;
        margin: 0 auto;
        font-family: sans-serif;
        margin-bottom: 20px;
    }

    input[type="checkbox"] {
        transform: scale(0.95);
    }

    .send-button {
        margin: 0 auto;
        background-color: #f46d33;
        border: 3px solid #fff;
        color: #fff;
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 2px;
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-weight: 500;
    }

    .send-button:hover {
        text-decoration: none;
        background-color: #fff;
        border: 3px solid #f46d33;
        color: #f46d33;
        transition: all 0.5s;
    }
}

@media(min-width: 2000px){
    .contact {
        /* background-image: url(/public/img/contact-background-fixed.png); */
        background-size: cover;
        display: flex;
        flex-direction: column;
        /* padding-top: 15vh; */
    }

    .contact-title {
        margin-top: 40px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 50px;
        font-family: Nauticus Sans;
    }

    .form {
        color: #fff;
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
        padding: 1rem 0;
    }

    input,
    textarea,
    select {
        background-color: transparent !important;
        border: 1px solid #fff;
    }

    .input {
        width: 350px;
        height: 60px;
        margin: 10px;
    }

    /*input que ocupa la totalidad*/
    .input-all {
        width: 720px;
    }

    .textarea {
        width: 720px;
        margin: 5px;
        height: 120px;
    }


    ::placeholder { /*estilos para las palabras dentro de las etiquetas del form*/
        padding: 8px;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.666);
        font-family: sans-serif;
    }

    select {
        color: rgba(255, 255, 255, 0.666);
        border: 1px solid #fff;
        font-size: 20px;
    }

    .select-space {
        padding-left: 8px;
    }

    .select-input {
        color: #000;
        font-size: 22px;
    }

    option {
        color: black;
    }

    .check-boxes {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        margin-left: 10px;
        font-size: 20px;
        margin-bottom: 30px;
        /* font-weight: 400!important; */
    }

    input[type="checkbox"] {
        transform: scale(0.95);
    }

    .check-boxes > label {
        font-weight: 300 !important;
        font-family: sans-serif;
    }

    label {
        display: inline-flex!important;
        gap: 0.5rem;
        align-items: flex-start;
    }

    .send-button {
        background-color: #f46d33;
        border: none;
        color: #fff;
        border: 3px solid #fff;
        text-transform: uppercase;
        font-size: 26px;
        letter-spacing: 2px;
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-weight: 700;
    }

    .send-button:hover {
        text-decoration: none;
        background-color: #fff;
        border: 3px solid #f46d33;
        color: #f46d33;
        transition: all 0.5s;
    }

    .contact_success{
        background-color: #7adf3c;
        border: none;
        color: #fff;
        border: 3px solid #fff;
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 2px;
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-weight: 700;
    }

    .error-message{
        background-color: rgb(234, 76, 76);
        padding: 5px 10px;
        font-family: Helvetica, sans-serif;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 10px;
    }

    .copy-text{
        margin-top: 15px;
    }

    /* .phone-container{
            position: relative;
            text-align: right;
            display: inline;
            right: -650px;
            top: -500px;
    } */

    .phone-img{
        width: 350px;
    }
}

.division-shape{
    top: -1px;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}

.division-shape svg{
    width: calc(157% + 1.3px);
    height: 43px;
}

.elementor-shape-fill{
    fill: #0056b8;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.phone-container{
    grid-area: phone-container;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 150px;
}
.alx-link-tel{
    display: block;
    margin: 0px auto;
}
@media(min-width: 1024px){
    .form{
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: ". formsFields phone-container" ". formsChecks .";
    }

    .phone-container{
        /*justify-content: left;*/
    }

    .input {
        width: 250px;
        height: 60px;
        margin: 10px;
    }

    .input-all, .textarea {
        width: 520px;
    }
}

.formsChecks{
    grid-area: formsChecks;
}

.formsFields{
    grid-area: formsFields;
}


.phone-container img{
    height: auto;
}

.blankspace{
    grid-area: blankspace;
}
