.header {
    background-image: url(/public/img/herobg.jpg);
    width: 100%;
    /* display: flex; */
    background-size: cover;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "center" "right" "arrow";
}

/*div de la izquierda*/
.left {
    margin-top: 100px;
    z-index: 1;
    grid-area: left;
}

.img-left {
    margin-left: -20px;
    width: 120%;
    margin-top: 40%;
}

/*div del medio*/
.center {
    margin-top: 100px;
    text-align: center;
    z-index: 0;
    grid-area: center;
}

.center-text {
    width: 92%;
    color: #fff;
    font-size: 20px;
    font-weight: 550;
    margin: 0 auto;
    font-family: sans-serif;
}

.arrow {
    margin: 0 auto;
    height: 60px;
    margin-top: 80px;
    animation-name: titileo;
    animation-duration: 1.8s;
    -webkit-animation-iteration-count: infinite;
}

.arrowArea{
    padding-bottom: 80px;
    grid-area: arrow;
    justify-self: center;
}

@keyframes titileo {
    0% {
        opacity: 0%;
    }
    30% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}

/*div de la derecha*/
.right {
    margin-top: 100px;
    grid-area: right;
}

.right-container {
    margin-top: 30%;
}

.img-right {
    margin-top: 25px;
    width: 100%;
}

.span-text {
    font-size: 20px;
    margin-top: 40px;
    display: flex;
    /* justify-content: space-evenly; */
    color: #fff;
    column-gap: 2rem;
}

.span-text2 {
    margin-top: 60px;
}

@media (max-width: 481px) {
    .header {
        background-image: url(/public/img/herobg.jpg);
        width: 100%;
        /* display: flex;
        background-size: cover;
        flex-direction: column; */
    }

    .left {
        margin-top: 0;
        display: none;
    }

    .center {
        margin: 0 auto;
        margin-top: 80px;
        width: 100%;
        text-align: center;

    }

    .img-center {
        width: 95%;
    }

    .center-text {
        width: 95%;
        margin: 0 auto;
        color: #fff;
        font-size: 18px;
    }

    .arrow {
        margin-top: 50px;
    }

    .right {
        margin-top: 30px;
        width: 100%;
        /* height: 500px; */
    }

    .span-text {
        display: flex;
        justify-content: space-between;
        color: #fff;
        width: 50%;
    }

    .span-text2 {
        margin-top: 30px;
    }

    .img-right {
        width: 90%;
        float: right;
    }

    .right-container {
        margin-top: 0;
    }
}

@media (min-width: 481px) and (max-width: 504px) {
    .header {
        background-image: url(/public/img/herobg.jpg);
        width: 100%;
        /* display: flex;
        background-size: cover;
        flex-direction: column; */
    }

    .left {
        display: none;
    }

    .center {
        margin-top: 80px;
        width: 100%;
        /* height: 550px; */
        text-align: center;
    }

    .img-center {
        width: 95%;
    }

    .center-text {
        width: 95%;
        margin: 0 auto;
        color: #fff;
        font-size: 18px;
    }

    .arrow {
        margin-top: 50px;
    }

    .right {
        margin-top: 0;
        width: 100%;
        /* height: 550px; */
    }

    .span-text {
        display: flex;
        /* justify-content: space-evenly; */
        color: #fff;
        margin-left: 5%;
        column-gap: 3rem;
        width: 50%;

    }

    .span-text2 {
        margin-top: 30px;
    }

    .img-right {
        width: 90%;
        float: right;
    }

    .right-container {
        margin-top: 0;
    }
}

@media (min-width: 505px) and (max-width: 604px) {
    .header {
        background-image: url(/public/img/herobg.jpg);
        width: 100%;
        /* display: flex;
        background-size: cover;
        flex-direction: column; */
    }

    .left {
        display: none;
    }

    .center {
        margin-top: 80px;
        width: 100%;
        /* height: 550px; */
        text-align: center;
    }

    .img-center {
        width: 95%;
    }

    .center-text {
        width: 95%;
        margin: 0 auto;
        color: #fff;
        font-size: 18px;
    }

    .arrow {
        margin-top: 50px;
    }

    .right {
        margin-top: 0;
        /* height: 550px; */
    }

    .span-text {
        display: flex;
        /* justify-content: space-evenly; */
        color: #fff;
        margin-left: 5%;
        column-gap: 3rem;
        width: 50%;
    }

    .span-text2 {
        margin-top: 30px;
    }

    .img-right {
        width: 90%;
        float: right;
    }

    .right-container {
        margin-top: 0;
    }
}

@media (min-width: 605px) and (max-width: 768px) {
    .header {
        background-image: url(/public/img/herobg.jpg);
        width: 100%;
        display: grid;
        background-size: cover;
        /* flex-direction: column; */
        grid-template-areas: "center" "right" "arrow";

    }

    .left {
        display: none;
    }

    .center {
        margin-top: 80px;
        width: 100%;
        text-align: center;
    }

    .img-center {
        width: 75%;
    }

    .center-text {
        width: 95%;
        margin: 0 auto;
        color: #fff;
        font-size: 20px;
    }

    .arrow {
        margin-top: 50px;
    }

    .right {
        margin-top: 0;
        width: 100%;
    }

    .span-text {
        display: flex;
        /* justify-content: space-evenly; */
        color: #fff;
        margin-left: 15%;
        column-gap: 4rem;

    }

    .span-text2 {
        margin-top: 30px;
    }

    .img-right {
        width: 90%;
        float: right;
    }

    .right-container {
        margin-top: 0;
    }
}

/* @media (min-width: 769px) and (max-width: 1024px) {
    .header {
        height: 750px;
    }
} */

@media (min-width: 769px){
    .header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "left center right" ". arrow .";
    }
}

/* ICONS */

.ingredient{
    width: 30px;
}

.ingrediente img{
    height: 55px;
}


@media (max-width: 1200px){

    .ingredient{
        width: 30px;
    }

    .ingrediente img{
        height: 45px;
    }
}

@media (min-width: 780px) and (max-width: 1200px){
    .span-text {
        column-gap: 1rem;
    }
}
