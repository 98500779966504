@font-face {
    font-family: "Nauticus Sans";
    src: local("Nauticus Sans Press Bold"), local("Nauticus-Sans-Press-Bold"),
        url("../fonts/NauticusSansPress-Bold.woff2") format("woff2"),
        url("../fonts/NauticusSansPress-Bold.woff") format("woff"),
        url("../fonts/NauticusSansPress-Bold.ttf") format("ttf"),
        url("../fonts/NauticusSansPress-Bold.svg") format("svg"),
        url("../fonts/NauticusSansPress-Bold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
