.btn-moreinfo {
    color: black;
    background-color: white !important;
    border: none;
    cursor: pointer;
    width: 300px;
    height: 60px;
    margin-bottom: 15px;
    display: block;
}
.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.btn-aceptar {
    background-color: #ffed00;
    text-decoration: none;
    padding: 6px 30px;
    color: #000;
    font-size: 1rem;
}

.buttons-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-end;
    /* height: 150px; */
}

.popup-fraude {
    background-color: #333333cf;
    color: white;
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    transition: height 1s ease;
    padding: 40px 0;
}

.btn-cerrar {
    border: none;
    cursor: pointer;
    opacity: 0.8;
    width: 300px;
    height: 60px;
    background-color: transparent;
    border: 1px solid white;
    color: white;
}

.btn-aceptar,
.btn-cerrar,
.btn-moreinfo {
    font-weight: 800;
}

.more-info {
    display: none;
    margin: 20px 0px;
}

.more-info div {
    width: 50%;
}

.popup-fraude p {
    z-index: 1;
    margin: 0;
    max-width: 700px;
    line-height: 26px;
}

.popup-title {
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    font-weight: 800;
}

.more-info a {
    color: yellow;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
}

.popup-cookies {
    height: 60px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 20px;
    background-color: white;
}

.leermas-link {
    text-decoration: none;
    color: #000;
    font-weight: 700;
}



.fraude-txt {
    padding-right: 10px;
    margin-right: 20px;
    border: transparent;
    font-size: .8rem;
}

.close {
    transform: translateY(130%);
}

#information {
    transition: height 0.5s ease, transform 0.5s ease;
}

.dudas-container {
    display: none;
    font-size: 12px;
}

.bold-txt {
    font-weight: 700;
}

.dudas-container p {
    margin-bottom: 10px;
}

#popup-container .container {
    display: flex;
    justify-content: space-evenly;
    column-gap: 4rem;
}

#more-info {
    display: none;
    flex-direction: row-reverse;
    color: yellow;
}

@media (max-width: 768px) {
    #popup-container .container {
        flex-direction: column;
        align-items: center;
    }

    .popup-fraude {
        height: 100% !important;
        padding: 30px 0px !important;
    }

    .btn-moreinfo,
    .btn-cerrar {
        width: 190px;
        height: 50px;
    }

    .popup-title {
        font-size: 24px;
    }

    .popup-fraude p {
        font-size: 14px;
    }

    .buttons-container {
        flex-direction: column;
        margin: 10px 0px;
    }

    .btn-moreinfo {
        margin-bottom: 0px;
    }

    .btn-moreinfo,
    .btn-cerrar {
        margin: 0px 10px;
    }
}

@media (max-width: 560px) {
    .btn-aceptar {
        margin-top: 10px;
    }

    .buttons-container {
        flex-direction: column;
    }

    .btn-moreinfo,
    .btn-cerrar {
        margin: 6px 0px;
    }
    .more-info {
        display: none !important;
    }
    h2.popup-title {
        text-align: center;
    }
}

@media (max-width: 992px) {
    .popup-fraude{
        height: auto;
        padding: 50px 0;
    }
}

@media (min-width: 992px) {
    .fraude-txt-opened {
        border-right: 1px solid white !important;
    }
}

@media (max-width: 878px) {
    #popup-container .container{
        flex-direction: column;
    }
    .buttons-container{
        align-items: center;
        row-gap: 1rem;
    }

    .fraude-txt{
        text-align: center;
    }
}
