.buy-section {
    width: 100%;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;
    background: #ff6e00;
}

/*div de la izquierda*/
.brands {
    text-align: center;
}

/*title*/
.brands-title {
    color: #fff;
    text-transform: uppercase;
    font-size: 10vw;
    font-family: Nauticus Sans;
    padding: 2rem;
}

.brands-img {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 2rem; */
    align-items: center;
    justify-items: center;
}

.brands-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

div.brands-img>div.brands-row-container
{
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.img-couple {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.img-logo {
    text-align: center;
    /* width: 7.5rem; */
    height: auto;
}

.bowl {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
    top: 50px;
    z-index: 5;
}

.bowl-img1 {
    width: 90%;
    height: auto;
}

.bowl-img2 {
    position: absolute;
    width: 80%;
    top: -25px;
    right: 50px;
}

@media (max-width: 480px) {
    .buy-section {
        flex-direction: column;
        gap: 2rem;
        padding: 4rem 0 0 0;
    }
    
    div.brands-img>div.brands-row-container
    {
        display: block;
    }

    div.brands-row-container>a
    {
        margin: 1rem;
    }
}

@media screen and (min-width: 581px) {
    .bowl {
        top: 5px;
    }
    .bowl-img2 {
        top: 50px;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .buy-section {
        flex-direction: column;
    }

    /*div de la izquierda, where-buy*/
    .brands {
        margin-top: 6%;
        width: 100%;
        text-align: center;
    }

    /*title*/
    .brands-title {
        color: #fff;
        text-transform: uppercase;
        font-size: 50px;
    }
}

@media screen and (min-width: 768px) {
    .buy-section {
        padding: 4rem 2rem;
    }

    .bowl {
        display: flex;
        align-items: center;
    }

    .bowl-img1 {
        width: 48vw;
    }

    .brands {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .brands-img{
        column-gap: 2rem;
    }

    #first-brand-logo
    {
        margin-right: 0.5rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .buy-section {
        width: 100%;
        padding: 2rem;
        display: flex;
    }

    /*title*/
    .brands-title {
        color: #fff;
        text-transform: uppercase;
        font-size: 2.5rem;
        font-weight: 900;
    }

    .img-logo {
        text-align: center;
        width: 15vw;
        height: auto;
    }

    .bowl-img1 {
        width: 400px;
    }

    .bowl-img2 {
        top: 17vw;
        right: 75px;
    }
}

@media screen and (min-width: 1024px) {
    .buy-section {
        padding: 0 15vw;
    }
    .brands {
        display: flex;
        flex-direction: column;
    }
    .brands-title {
        font-size: 4rem;
    }
    .brands-img {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 1rem;
    }

    .bowl-img2 {
        right: 10vw;
    }

    .img-logo{
        width: 7.5rem;
    }
}

@media screen and (min-width: 1400px) {

    .bowl-img1 {
        width: 600px;
    }

    .bowl-img2 {
        left: -5px;
        width: 30rem;
        top: 1vw;
    }
}
