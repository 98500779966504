.CookieConsent{
    padding: 10px 80px !important;
}

.CookieContent{
    margin: 15px 0 !important;
    margin-right: 10px !important;
}

@media (max-width: 978px) {
    .CookieConsent{
        padding: 10px 50px !important;
    }
}

@media (max-width: 590px) {
    .CookieConsent{
        padding: 10px 20px !important;
    }
}
