@import url(/src/fonts/style.css);
@import url(header.css);
@import url(nav.css);
@import url(contact.css);
@import url(footer.css);
@import url(products-sections.css);
@import url(buy.css);
@import url(game.css);
@import url(cookies.css);
@import url(modal.css);
@import url(not-found.css);
@import url(privacy.css);

html {
    scroll-behavior: smooth;
  }

body{
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}

:root {
    --main-bg-color: #0056b8;
}

* {
    box-sizing: border-box;
}

/*colors
  -bg-color footer: #0056b8
  -section first title: rgb(255, 110, 0)
  -section second title: rgb(0, 86, 184)
*/

/*queries
@media(max-width: 480px){

}
@media(min-width: 481px) and (max-width: 768px){

}
@media(min-width: 769px) and (max-width: 1024px){

}
@media(min-width: 1025px) and (max-width: 1200px){

}
@media(min-width: 1201px){

}
*/
