.nav {
    background-color: #0056b8;
    width: 100%;
    height: 75px;
    font-family: "Nauticus Sans";
    /* padding: 10px 0; */
    justify-content: center;
    align-items: center;
}

.link {
    color: #fff;
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
    color: #fff;
}

/*list*/
.nav-ul {
    text-transform: uppercase;
    list-style-type: none;
    text-align: center;
    position: relative;
    margin-block-start: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    column-gap: 3rem;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;

}

/* BurgerNav */

/* nav-icon-5 */
.nav-icon-5{
    width: 35px;
    height: 30px;
    margin: 10px 10px;
    position: relative;
    cursor: pointer;
    display: inline-block;
  }
  .nav-icon-5 span{
    background-color:#fff;
    position: absolute;
    border-radius: 2px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    width:100%;
    height: 4px;
    transition-duration: 500ms
  }
  .nav-icon-5 span:nth-child(1){
    top:0px;
    left: 0px;
  }
  .nav-icon-5 span:nth-child(2){
    top:13px;
    left: 0px;
    opacity:1;
  }
  .nav-icon-5 span:nth-child(3){
    bottom:0px;
    left: 0px;
  }

  .nav-icon-5.open span:nth-child(1){
    transform: rotate(45deg);
    top: 13px;
  }
  .nav-icon-5.open span:nth-child(2){
    opacity:0;
  }
  .nav-icon-5.open span:nth-child(3){
    transform: rotate(-45deg);
    top: 13px;
  }

  .nav-responsive{
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #0056b8;
    z-index: 11;
    align-items: center;
  }

.nav-ul a{
    text-decoration: none;
}

.burger-container {
    position: absolute;
    top: 6%;
    margin: 0 auto;
    left: 49%;
}

.nav-logo-img {
    display: none;
}

.menu-icon {
    color: #fff;
    font-size: 20px;
}

.menu-icon:hover {
    color: #f46d33;
}

@media (max-width: 481px) {

}

@media (min-width: 768px) {
    .nav {
        height: 120px;
    }

    .burger{
        display: none;
    }

    .nav-responsive{
        display: none;
    }
}

@media (max-width: 768px) {
    .nav-logo-img {
        display: block;
        text-align: center;
    }
    .container-nav{
        padding: 0 !important;
        z-index: 1;
    }
    .navbar-collapse {
        /* margin-top: 30px; */
        background-color: #0056b8d0;
        transition: all .5s;
        padding-left: 0;
        padding-right: 0;
    }
    .nav-ul {
        padding-top: 2rem;
        padding-bottom: 1rem;
        z-index: 10;
        width: 100%;
        display: flex;
        row-gap: 1rem;
        flex-direction: column;
        text-align: center;
        transition: all .5s;
    }
    .nav-opened{
        /* display: none; */
        margin-top: 0;
    }
    .nav-closed{
        /* display: none; */
        margin-top: -240px;
    }


}

.collapse:not(.show){
    display: flex;
}