.not-found {
    font-family: Nauticus Sans, sans-serif;
    padding: 80px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
}

.title-nf {
    color: #ff6e00;
    font-size: 5rem;
}

.text-nf {
    font-size: 30px;
}

.mini-text-nf {
    font-size: 12px;
}

.back-home {
    background-color: #0056b8;
    color: #fff;
    border: 3px solid #f46d33;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 2px;
    padding: 18px 36px;
    font-weight: 700;
    text-decoration: none;
}

.back-home:hover {
    text-decoration: none;
    background-color: #fff;
    border: 3px solid #f46d33;
    color: #f46d33;
    transition: all 0.5s;
}

@media screen and (min-width: 768px) {
    .title-nf {
        font-size: 10rem;
    }
    .text-nf {
        font-size: 1.5rem;
    }
    .mini-text-nf {
        font-size: 1rem;
    }
}
