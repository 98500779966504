.game-container {
    width: 100%;
    background: url(/public/img/playbg.webp);
    background-size: 92%;
    position: relative;
    padding: 50px 0 110px;
}

/*contenedor del juego 1*/
.game1-containers {
    display: flex;
}

.game-title-div {
    padding-top: 60px;
    text-transform: uppercase;
}

.game-title {
    text-align: center;
    color: rgb(0, 86, 184);
    font-weight: 900;
    font-size: 35px;
    font-family: Nauticus Sans;
}

.game-subtitle {
    text-align: center;
    color: #7a7a7a;
    font-size: 16px;
    font-family: sans-serif;
    text-transform: initial;
}

/*left container*/
.left-game-container {
    width: 33.3%;
}

/*center container*/
.center-game-container {
    width: 33.3%;
}

.game {
    width: 300px;
    height: 600px;
    /* background-color: #000; */
    margin: 0 auto;
}

/*right container*/
.right-game-container {
    text-transform: uppercase;
    width: 33.3%;
    height: 600px;
    margin-top: 20px;
}

/*div que contiene al div de la flecha y el texto*/
.controls {
    margin-top: 200px;
}

/*div que engloba a la flehca y el texto*/
.game-arrow-text {
    display: flex;
    flex-direction: row;
}

.controls-text {
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: bolder;
    color: black;
    font-family: Nauticus Sans;
}

.arrow-game-img {
    height: 35px;
    margin-top: -5px;
    margin-right: 4px;
    rotate: ;
}

/*queries*/
@media (max-width: 1024px) {
    .game1-containers {
        display: none;
    }
}

.division-shape-bottom{
    bottom: -1px;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}

.division-shape-bottom svg{
    width: calc(157% + 1.3px);
    height: 43px;
}

.elementor-shape-fill{
    fill: #0056b8;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    rotate: 180deg;
}

.division-shape-top{
    top: -1px;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}

.division-shape-top svg{
    width: calc(157% + 1.3px);
    height: 43px;
}

.elementor-shape-fill-top{
    fill: #ff6e00;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    /* rotate: 180deg; */
}

.arrow-left{
    rotate: 90deg;
}

.arrow-right{
    rotate: -90deg;
}

.arrow-up{
    rotate: 180deg;
}

/* Second Game */

/*contenedor del juego 1*/
.game2-containers {
    display: flex;
    justify-content: center;
}


.game2-text {
    text-align: center;
    color: #7a7a7a;
    font-size: 16px;
    font-family: sans-serif;
    text-transform: initial;
}

/*middle container*/
.middle-game-container {
    width: 90%;
}

.info-game2{
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #7a7a7a;
}

.win-message {
    text-align: center;
    color: rgb(0, 86, 184);
    font-weight: 700;
    font-size: 2rem;
    font-family: Nauticus Sans;
}

.btn-restart{
    background-color: #ff6f0037;
    color: #323232;
    border: 1px solid #ff6e00;
    border-radius: 5px;
    font-size: 20px;
    transition: all .3s;
}

.btn-restart:hover{
    background-color: #0056b894;
    color: white;
    border: 1px solid #0056b8;
    border-radius: 5px;
    font-size: 20px;
    transition: all .3s;
}

/*queries*/
@media (min-width: 1025px) {
    .game2-containers {
        display: none;
    }
}
