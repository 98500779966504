.privacy {
    padding: 80px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    gap: 4rem;
}

.privacy a {
    text-decoration: none;
}

.privacy a:hover {
    color: #f67633;
}

.privacy p,
.privacy ol {
    color: #7a7a7a;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
}

.privacy h1 {
    color: #0056b8;
    font-size: 3rem;
    font-family: Nauticus Sans, sans-serif;
    padding-bottom: 4rem;
}

.privacy h2 {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
    padding-bottom: 0.25rem;
}

@media screen and (min-width: 768px) {
    .privacy {
        padding: 80px 12rem;
        margin: 0 auto;
        max-width: 1100px;
    }
}
